import axios from 'axios'
import store from '@/store/index'

let baseURL = process.env.VUE_APP_API_BASE_URL

const Service = axios.create({
	baseURL: baseURL,
})

Service.interceptors.request.use(config => {
	config.headers['token'] = store.state.loginToken
		? store.state.loginToken.token
		: null
	return config
})

Service.interceptors.response.use(undefined, err => {
	const response = err.response
	// eslint-disable-next-line no-unused-vars
	return new Promise(function(resolve, reject) {
		if (
			response &&
			response.status === 401 &&
			err.config &&
			!err.config.__isRetryRequest
		) {
			// if the user gets unauthorized, logout the user
			if (store.state.loginToken != null) {
				store.dispatch('logout')
			}
		}
		throw err
	})
})

export default {
	//Login, Logout
	login(formData) {
		return Service.post('/login', formData, null)
	},
	logout() {
		return Service.post('/logout')
	},

	// Password
	passwordReset(formData) {
		return Service.post('/password/reset', formData)
	},
	passwordNew(formData) {
		return Service.put('/password/new', formData)
	},

	// Users
	// getUsers() {
	// 	// Will be removed
	// 	console.warn('Method will be removed!')
	// 	return Service.get('/user/all')
	// },
	createUser(formData) {
		return Service.post('/user', formData)
	},
	updateUser(id, formData) {
		return Service.put('/user/' + id, formData)
	},
	deleteUser(userId) {
		return Service.delete('/user/' + userId)
	},
	getSelf() {
		return Service.get('/user/me')
	},
	updateSelf(formData) {
		return Service.put('/user/me', formData)
	},
	updatePassword(formData) {
		return Service.post('/user/me/password', formData)
	},
	getUserAccessInvite(token) {
		return Service.get('/user-access-invite/' + token)
	},
	registerUser(formData, token) {
		return Service.post('/user-access-invite/' + token, formData)
	},
	respondAccessInvite(formData) {
		return Service.post(`/user-access-invite/respond`, formData)
	},
	inviteUserToBrand(formData) {
		return Service.post('/user-access-invite/inviteToBrand', formData)
	},
	inviteUserToCompany(formData) {
		return Service.post('/user-access-invite/inviteToCompany', formData)
	},
	cancelAccessInvite(id) {
		return Service.delete('/user-access-invite/' + id)
	},
	deleteAccess(id) {
		return Service.delete('/user-access/' + id)
	},

	// Budget options
	createBudgetOption(formData) {
		return Service.post('/budget-option', formData)
	},
	updateBudgetOption(id, formData) {
		return Service.put('/budget-option/' + id, formData)
	},
	deleteBudgetOption(budgetOptionId) {
		return Service.delete('/budget-option/' + budgetOptionId)
	},

	// Brand
	myBrands(currentBrandId) {
		const activeBrandId = currentBrandId
			? `?activeBrandId=${currentBrandId}`
			: ''
		return Service.get('/brand/my' + activeBrandId)
	},
	getBrand(id) {
		return Service.get('/brand/' + id)
	},
	createBrand(formData) {
		return Service.post('/brand', formData)
	},
	updateBrand(brandId, formData) {
		return Service.put('/brand/' + brandId, formData)
	},
	linkedinLocationSearch(searchword) {
		return Service.get('/linkedin-location-search?searchword=' + searchword)
	},

	// Company
	getCompanies() {
		return Service.get('/company/all')
	},
	getCompany(id) {
		return Service.get('/company/' + id)
	},
	createCompany(formData) {
		return Service.post('/company', formData)
	},
	updateCompany(id, payload) {
		return Service.put('/company/' + id, payload)
	},
	deleteCompany(id) {
		return Service.delete('/company/' + id)
	},

	// Campaigntype
	createCampaignType(formData) {
		return Service.post('/campaign-type', formData)
	},
	getCampaignType(id) {
		return Service.get('/campaign-type/' + id)
	},
	updateCampaignType(id, formData) {
		return Service.put('/campaign-type/' + id, formData)
	},
	deleteCampaignType(id) {
		return Service.delete('/campaign-type/' + id)
	},
	moveCampaignType(id, payload) {
		return Service.put(`/campaign-type/${id}/move`, payload)
	},

	// CampaignTypeSize
	getCampaignTypeSizes() {
		return Service.get('/default-campaign-type-size/all')
	},

	// CampaignOrders
	getCampaignOrders() {
		return Service.get('/campaign-order/all')
	},
	getCampaignOrder(id) {
		return Service.get('/campaign-order/' + id)
	},
	uploadCampaignPicture(payload) {
		return Service.post('/campaign-field-picture', payload)
	},
	uploadCampaignVideo(payload) {
		return Service.post('/campaign-field-video', payload)
	},
	uploadCampaignAudio(payload) {
		return Service.post('/campaign-field-audio', payload)
	},
	saveCampaignPictureJson(id, payload) {
		return Service.put('/campaign-field-picture/' + id, payload)
	},
	saveCampaignVideoJson(id, payload) {
		return Service.put('/campaign-field-video/' + id, payload)
	},
	saveCampaignAudioJson(id, payload) {
		return Service.put('/campaign-field-audio/' + id, payload)
	},
	createCampaignOrder(formData) {
		return Service.post('/campaign-order', formData)
	},
	createCampaignOrders(formData) {
		return Service.post('/campaign-orders', formData)
	},
	updateCampaignOrder(id, formData) {
		return Service.put('/campaign-order/' + id, formData)
	},
	cancelCampaignOrder(id) {
		return Service.put('/campaign-order/' + id + '/cancel')
	},
	getCampaignOrderAd(id) {
		return Service.get('/campaign-order/' + id + '/ad')
	},
	getReport(id) {
		return Service.get('/campaign-order/' + id + '/report')
	},
	fetchBillableOrders() {
		return Service.get('/campaign-order/billable')
	},
	billCampaignOrder(id) {
		return Service.post('/campaign-order/' + id + '/bill')
	},
	// Counties
	getCounties() {
		return Service.get('/county/all')
	},

	// Import & Export of companies
	importCompanies(payload) {
		return Service.post('/import/run', payload)
	},
}
